export const sliderItems = [
  {
    id: 1,
    img: 'https://zupimages.net/up/22/16/ku1w.jpg',
    title: 'Collection été',
    desc: 'Ne faites pas de compromis sur le style! Obtenez 30 % pour les nouveaux arrivants',
    bg: 'fef3ed',
  },
  {
    id: 2,
    img: 'https://zupimages.net/up/22/16/x57j.jpg',
    title: 'Collection Automne',
    desc: 'Ne faites pas de compromis sur le style! Obtenez 30 % pour les nouveaux arrivants',
    bg: 'fcf1ed',
  },
  {
    id: 3,
    img: 'https://zupimages.net/up/22/16/10qc.jpg',
    title: 'Collection Sport',
    desc: 'Ne faites pas de compromis sur le style! Obtenez 30 % pour les nouveaux arrivants',
    bg: 'fbf0f4',
  },
];

export const categories = [
  {
    id: 1,
    img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "STYLE CHEMISE",
    cat: "femme",
  },
  {
    id: 2,
    img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "TEE-SHIRT ",
    cat: "manteau",
  },
  {
    id: 3,
    img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    title: "VESTES LÉGÈRES",
    cat: "jeans",
  },
  {
    id: 4,
    img: "https://images.pexels.com/photos/852860/pexels-photo-852860.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    title: "ROBES D'ETE",
    cat:"robe"
  },
  {
    id: 5,
    img:"https://cdn.pixabay.com/photo/2014/07/31/23/42/legs-407196_960_720.jpg",
    title: "CHAUSSURE",
    cat: "chaussure"
  }
];

export const popularProducts = [
  {
    id:1,
    img:"https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
  },
  {
    id:2,
    img:"https://www.pngarts.com/files/5/Plain-Pink-T-Shirt-PNG-Image.png",
  },
  {
    id:3,
    img:"https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
  },
  {
    id:4,
    img:"https://www.burdastyle.com/pub/media/catalog/product/cache/7bd3727382ce0a860b68816435d76e26/107/BUS-PAT-BURTE-1320516/1170x1470_BS_2016_05_132_front.png",
  },
  {
    id:5,
    img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
  },
  {
    id:6,
    img:"https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
  },
  {
    id:7,
    img:"https://www.pngarts.com/files/5/Plaid-Skirt-Free-PNG-Image.png",
  },
  {
    id:8,
    img:"https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
  },
]